.dark {
  --primary: #ffb121;
  --success: #2fdd89;
  --warning: #ef4646;
  --light-FFFFFF-262626: 38, 38, 38;
  --light-D9D9D9-333333: 51, 51, 51;
  --light-808080-404040: 64, 64, 64;
  --light-404040-808080: 128, 128, 128;
  --light-333333-D9D9D9: 217, 217, 217;
  --light-262626-F7F7F7: 247, 247, 247;
  --light-F7F7F7-333333: 51, 51, 51;
  --light-FFFFFF-404040: 64, 64, 64;
  --light-808080-D9D9D9: 217, 217, 217;
  --light-262626-D9D9D9: 217, 217, 217;
  --light-D9D9D9-808080: 128, 128, 128;
}
.light {
  --primary: #ffb121;
  --success: #2fdd89;
  --warning: #ef4646;
  --light-FFFFFF-262626: 255, 255, 255;
  --light-D9D9D9-333333: 217, 217, 217;
  --light-808080-404040: 128, 128, 128;
  --light-404040-808080: 64, 64, 64;
  --light-333333-D9D9D9: 51, 51, 51;
  --light-262626-F7F7F7: 38, 38, 38;
  --light-F7F7F7-333333: 247, 247, 247;
  --light-FFFFFF-404040: 255, 255, 255;
  --light-808080-D9D9D9: 128, 128, 128;
  --light-262626-D9D9D9: 38, 38, 38;
  --light-D9D9D9-808080: 217, 217, 217;
}
