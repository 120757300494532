@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap);
.styles_container__3OQSl {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgb(var(--light-FFFFFF-262626), 0.5);
  background: rgb(var(--light-FFFFFF-262626));
}

.theme_dark__1rgv2 {
  --primary: #ffb121;
  --success: #2fdd89;
  --warning: #ef4646;
  --light-FFFFFF-262626: 38, 38, 38;
  --light-D9D9D9-333333: 51, 51, 51;
  --light-808080-404040: 64, 64, 64;
  --light-404040-808080: 128, 128, 128;
  --light-333333-D9D9D9: 217, 217, 217;
  --light-262626-F7F7F7: 247, 247, 247;
  --light-F7F7F7-333333: 51, 51, 51;
  --light-FFFFFF-404040: 64, 64, 64;
  --light-808080-D9D9D9: 217, 217, 217;
  --light-262626-D9D9D9: 217, 217, 217;
  --light-D9D9D9-808080: 128, 128, 128;
}
.theme_light__3Nt6Z {
  --primary: #ffb121;
  --success: #2fdd89;
  --warning: #ef4646;
  --light-FFFFFF-262626: 255, 255, 255;
  --light-D9D9D9-333333: 217, 217, 217;
  --light-808080-404040: 128, 128, 128;
  --light-404040-808080: 64, 64, 64;
  --light-333333-D9D9D9: 51, 51, 51;
  --light-262626-F7F7F7: 38, 38, 38;
  --light-F7F7F7-333333: 247, 247, 247;
  --light-FFFFFF-404040: 255, 255, 255;
  --light-808080-D9D9D9: 128, 128, 128;
  --light-262626-D9D9D9: 38, 38, 38;
  --light-D9D9D9-808080: 217, 217, 217;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  outline: none;
}

h1,
h2,
h3,
p,
span,
div {
  padding: 0;
  margin: 0;
}

.background {
  background: rgb(var(--light-F7F7F7-333333));
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #ffb121;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
}

.wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px rgb(var(--light-FFFFFF-262626)) inset !important;
  -webkit-text-fill-color: rgb(var(--light-262626-D9D9D9)) !important;
}

.padding {
  padding-bottom: 30px;
}

.pointer {
  cursor: pointer;
}

/*  */
.label-bg-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px 40px 40px 16px;
  color: #333;
  flex-wrap: wrap;
}
.label-bg-wrapper span:nth-child(2) {
  font-size: 0.875em;
}
.label-bg-wrapper > span {
  font-size: 1.2em;
  margin-right: 2px;
}
.c0 {
  background: #fb6868;
  background-image: linear-gradient(to right, #fb6868 0, #fe8888 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffb6868', endColorstr='#fffe8888', GradientType=1);
  border-left: 2px solid #fb4848;
  border-bottom: 1px solid #fb4848;
}
.c10 {
  background: #fcb258;
  background-image: linear-gradient(to right, #fcb258 0, #fcd278 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcb258', endColorstr='#fffcd278', GradientType=1);
  border-left: 2px solid #fc9238;
  border-bottom: 1px solid #fc9238;
}
.c20 {
  background: #f8ff53;
  background-image: linear-gradient(to right, #f8ff53 0, #faff93 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8ff53', endColorstr='#fffaff93', GradientType=1);
  border-left: 2px solid #d8df53;
  border-bottom: 1px solid #d8df53;
}
.c30 {
  background: #c2ff88;
  background-image: linear-gradient(to right, #c2ff88 0, #e2ffa8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc2ff88', endColorstr='#ffe2ffa8', GradientType=1);
  border-left: 2px solid #a2df68;
  border-bottom: 1px solid #a2df68;
}
.c40 {
  background: #88ff54;
  background-image: linear-gradient(to right, #88ff54 0, #a8ff74 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff88ff54', endColorstr='#ffa8ff74', GradientType=1);
  border-left: 2px solid #68df24;
  border-bottom: 1px solid #68df24;
}
.c50 {
  background: #22ff09;
  background-image: linear-gradient(to right, #22ff09 0, #42ff29 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff22ff09', endColorstr='#ff42ff29', GradientType=1);
  border-left: 2px solid #02df09;
  border-bottom: 1px solid #02df09;
}
.tableTitle {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: auto !important;
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: unset !important;
}

.chart-tooltips {
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
}
.chart-tooltips p {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
}
.chart-tooltips p:last-child {
  margin-bottom: 0;
}
.tblbody-noborder table tbody tr td {
  border-bottom: 0;
}
.tblTitleRight > span {
  justify-content: flex-end;
}
.tblTitleRight > div {
  display: flex;
  justify-content: flex-end;
}
.tbl-noshadow {
  box-shadow: none;
}
th.MuiTableCell-root {
  font-size: 0.75rem;
}
.MuiTableCell-root {
  padding: 15px 10px;
}

.k-line-chart-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  width: 100%;
  height: 600px;
  padding: 15px;
}
.k-line-chart {
  display: flex;
  flex: 1 1;
}
.k-line-chart-title {
  padding-bottom: 15px;
}

.video-resp {
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 82%;
}
.video-resp iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
/*  */
@media (min-width: 576px) {
  .wrapper {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}

